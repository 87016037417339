import '../styles/Second.css';
import React from 'react';
import { polyfill } from "seamless-scroll-polyfill";

function Second(props) {
  polyfill();
  const {projects} = props
  const [currentProject, setCurrentProject] = React.useState(0)

  const formatTechText = (tech) => {
    switch(tech){
      case 'react': return 'React';
      case 'css': return 'CSS3';
      case 'html': return 'HTML5';
      case 'js': return 'JavaSript';
      case 'rpi': return 'RaspberryPi';
      case 'sqlite': return 'SQLite';
      case 'flask': return 'Flask';
      case 'python': return 'Python';
      case 'mongo': return 'MongoDB';
      case 'node': return 'node.js';
    }
  }

  const handleProject = (what) => {
    switch(what){
      case 'add':
        if(projects.length>currentProject+2){
          document.getElementsByClassName('oldproject')[currentProject+2].scrollIntoView({behavior:"smooth",block: 'nearest',inline: "nearest"})
          setCurrentProject(prevState=>prevState+2)
        }
        break;
      case 'sub':
        if(0<=currentProject-2){
          document.getElementsByClassName('oldproject')[currentProject-2].scrollIntoView({behavior:"smooth",block: 'nearest',inline: "nearest"})
          setCurrentProject(prevState=>prevState-2)

        }
        break;
      default: return;
    }
  }

  const pageReady = (state) => {
    switch(state){
      case '#': return(<div className='oldproject-hover-button-text' style={{color:"#f55"}}>project stuck...</div>)
      case '@': return(<div className='oldproject-hover-button-text' style={{color:"#ff5"}}>in progress...</div>)
      default: return(<a href={state} target="_blank" rel="noopener" className='oldproject-hover-button'>check it!</a>)
    }
  }

  return (
    <>
    <div className='oldprojects-page'>
      <div className='oldprojects-title'>My projects:</div>
      <div className='oldcircle-buttons'>
        <svg onClick={()=>handleProject('sub')} width={55} height={55} className='oldscroll-button'>
        <circle cx={27} cy={27} r={25} fill='#fff' stroke='#0002'strokeWidth={2}/>
          <line x1={31} y1={15} x2={18.5} y2={27.5} stroke='#000'strokeWidth={2}/>
          <line x1={31} y1={40} x2={18.5} y2={27.5} stroke='#000'strokeWidth={2}/>
        </svg>
        <svg onClick={()=>handleProject('add')} className='oldscroll-button' style={{transform:'rotate(180deg)'}} width={55} height={55}>
          <circle cx={27} cy={27} r={25} fill='#fff' stroke='#0002'strokeWidth={2}/>
          <line x1={31} y1={15} x2={18.5} y2={27.5} stroke='#000'strokeWidth={2}/>
          <line x1={31} y1={40} x2={18.5} y2={27.5} stroke='#000'strokeWidth={2}/>
        </svg>
      </div>
    <div className='oldprojects'>
      {projects && projects.map(item => {
        return(
          <div title={item.description} className='oldproject'>
          <div className='oldproject-inside'>
            <div className='oldproject-image'>
            <div className='oldproject-title'>{item.title}</div>
            <img src={require(`../images/${item.image}.webp`)}/>
            </div>
            {pageReady(item.url)}
            <div className='oldproject-hover-info'>
              <div className='oldproject-hover-info-text'>technologies used:</div>
            <svg >
              <line x1="0" y1="0" x2="500" y2="0" style={{stroke:'#fffa',strokeWidth:'2'}}/>
            </svg>
            <div className='oldproject-technologies'>
            {item.technologies.map(tech=>{return(
            <img title={formatTechText(tech)} className='oldproject-technologies-element' src={require(`../images/${tech}.webp`)}/>
            )})}
          </div>
          </div>
      </div>
      </div>
        )
      })}
    </div>
    </div>
    </>
  );
}

export default Second;
