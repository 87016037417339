import { useState, useEffect, Suspense, useRef } from "react"
import Loading from "../items/Loading"
import Project from "../items/Project"
import "../styles/projects.css"
import axios from "axios"
import Modal from "react-modal"
import ProjectModal from "../items/ProjectModal"
Modal.setAppElement('#root')

const customStyles = {
    content: {
      background: "#334756",
      border:"none",
      borderRadious: "40px"
    },
  };

export default function Projects(props){
    const [projects,setProjects] = useState(undefined)
    const [details,setDetails] = useState(undefined)
    const [showDetails,setShowDetails] = useState(false)

    useEffect(()=>{
        axios.get("/json/projects.json").then((data)=>{setProjects(data.data)})
    },[])

    useEffect(()=>{
        if(details)setShowDetails(true)
        else setShowDetails(false)
    },[details])

    const modalClosed = () => {
        setDetails(undefined)
    }

    return(
        <>
            <div id="projects_div" ref={props.projectsRef}>
                <img id="about_bye" src="/images/buildingBlocks/aboutBye.webp"/>
                <div id="dont-ask">
                    <div className="top">Don't ask me <p>if I <b>can</b></p> do it,</div>
                    <br/>
                    <div className="bottom">ask me <p><b>how fast</b> can I</p> do it.</div>
                </div>

                <div className="title" id="projects">My projects:</div>
                <Suspense fallback={<Loading/>}>
                    <div className="projects">
                        {projects && projects.map((item2,index)=><Project key={index} project={item2} setDetails={setDetails}/>)}
                        
                    </div>
                </Suspense>

            </div>

            <Modal
                isOpen={showDetails}
                onAfterClose={()=>modalClosed()}
                closeTimeoutMS={400}
                onRequestClose={()=>setShowDetails(false)}
                style={customStyles}
                contentLabel="Example Modal">
                    <ProjectModal setIsOpen={setShowDetails} project={details}/>
            </Modal>

            
        </>
    )
}