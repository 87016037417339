import '../styles/First.css';
import React from 'react';

function First(props) {


  return (
    <>
      <div className='oldwelcome'>
        <div className='oldtitle-welcome'>Hi,</div>
        <div className='oldtitle'>I'm Tomasz</div>
        <div className='oldtitle-desc'>Frontend developer<a href = "mailto: vlipczynski@gmail.com" target="_blank" rel="noopener" >
            <img src={require('../images/email.webp')}/>
          </a>
          <a href="https://github.com/v714" target="_blank" rel="noopener" >
            <img src={require('../images/github.webp')}/>
          </a></div>
        <div className='oldtechs'>
          <div className='oldtechs-title'>technologies I use:</div>
          <div className='oldtechs-imgs'>
              <img title='HTML' src={require('../images/html.webp')}/>
              <img title='CSS' src={require('../images/css.webp')}/>
              <img title='JavaScript' src={require('../images/js.webp')}/>
              <img title='React' src={require('../images/react.webp')}/>
              <img title='Python' src={require('../images/python.webp')}/>
            </div>
        </div>
     
      </div>
    </>
  );
}

export default First;
