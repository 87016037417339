import $ from 'jquery'
import React from 'react'
import './abyss.css'

function Abyss(props) {

    React.useEffect(()=>{
        $( ".secret2" ).hover(
            function() {
         if($("#circle").position().left>$(".secret2").position().left+5 && $("#circle").position().left<$(".secret2").position().left+50){
             
             let pos = $("#circle").position();
             $(".secret2").css({cursor:"not-allowed"})
             $("#circle").css({"animation":"none","left":pos.left})
             $("#circle").css({cursor:"unset"})
             $("#pseudo-background").css({animation:'none'});
            $("#circle").animate({left:(($(window).width()/2) - 15)}, 1500);
            $("#pseudo-body").animate({backgroundColor:'black'}, 1500);
             $("#pseudo-body").css({"animation":"white 1.7s 1 linear 1.5s forwards"})
             $(".wave").animate({backgroundColor:'#c9c9c9',border:'solid 1px #070707'},1500);
             $("#finished").css({opacity:1});
             props.setDoneAbyss(true)
            }
         }
 
         );
    },[])
       
  

    return(
        <div id="after-background">
            {props.abyss?<button onClick={()=>props.setAbyss(false)} style={{filter: props.doneAbyss?"invert(1)":"invert(0)"}} id='leave_abyss'><img src='/images/arrows.svg'/></button>:<></>}
            <div id="pseudo-body">
                <div id="pseudo-background"></div>
                <div className="secret2"></div>
                <div id="circle"></div>
                <div className="secret"></div>
                <div className="wave w1"></div>
                <div className="wave w2"></div>
                <div className="wave w3"></div>
                <div id="finished">Thanks for playing...</div>
            </div>
        </div>
    )
}

export default Abyss;