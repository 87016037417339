import $ from 'jquery'
import React from 'react'
import './abyss.css'

function Abyss(props) {

    React.useEffect(()=>{
        $( "#abyssCircle" ).hover(
            function() {
         if($("#ball").position().left>(-30) && $("#ball").position().left<$("#abyssCircle").position().left+15){
            
            $("#ball").animate({animation:"none",left:"-13px",top:"-13px"}, 1000)
            $(".cube-front").css({animation:"none",opacity:0})
            $(".cube-back").css({animation:"none",opacity:0})
            $(".cube-top").css({animation:"none",opacity:0})
            $(".cube-bottom").css({animation:"none",opacity:0})
            $(".cube-left").css({animation:"none",opacity:0})
            $(".cube-right").css({animation:"none",opacity:0})
            $(".abyss-circle").css({borderColor:"#0af",borderWidth:"3px"})
            $("#abyssCircle").addClass("hover-after")
            $("#abyssCircle").css({cursor:"not-allowed"})
            setTimeout(()=>{
                $(".abyss-wave-floor").css({borderColor:"#aaa",backgroundColor:"#f0f0f0"})
                $("#abyssBody").css({backgroundColor:"#fff"})
                $("#abyssThanks").css({opacity:"1"})
                $(".flat2").css({backgroundImage:"radial-gradient(#0004 0%,#ffff 70%), repeating-conic-gradient(from 45deg, #eee 0deg 90deg, #ddd 90deg 180deg)"})
            },1500)
           
            $("#finished").css({opacity:1});
            props.setDoneAbyss(true)
         }}
    );
    },[])
       
  

    return(
        <div id="abyssBody">
                {props.abyss?<button onClick={()=>props.setAbyss(false)} style={{filter: props.doneAbyss?"invert(1)":"invert(0)"}} id='leave_abyss'><img src='/images/arrows.svg'/></button>:<></>}
        <div id="abyssThanks">Thanks for Playing!</div>
        <div id="abyssScene">

            <div class="flat2"></div>
            <div id="abyssCircle">
                <div class="abyss-circle"></div>
                <div class="abyss-circle"></div>
                <div class="abyss-circle"></div>
            </div>
            <div id="ball">
                <div id="ballIn"></div>
                <div class="cube-front"></div>
                <div class="cube-back"></div>
                <div class="cube-top"></div>
                <div class="cube-bottom"></div>
                <div class="cube-left"></div>
                <div class="cube-right"></div>
            </div>
            
            <div id="floorWaves">
                <div class="abyss-wave-floor"></div>
                <div class="abyss-wave-floor"></div>
                <div class="abyss-wave-floor"></div>
            </div>
        </div>
    </div>
    )
}

export default Abyss;