import "../styles/contact.css"
import { Fade } from "react-reveal"
import { useState } from "react"
import emailjs from "emailjs-com"
import ReactTooltip from "react-tooltip"

const SERVICE_ID = "service_usef9ar";
const TEMPLATE_ID = "template_k2gknc6";
const USER_ID = "ifcP37wOKlbbluT66";

export default function Contact(props){
    const [name,setName] = useState('')
    const [email,setEmail] = useState('')
    const [message,setMessage]=useState('')
    const [isSent,setSent]=useState(undefined)

    const handleSubmit = async(e) => {

        e.preventDefault();
        setSent({color:"#bbb",text:"please wait..."})
         emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
          .then((result) => {
            console.log(result.text);
            setSent({color:"#0f0",text:"Message sent!"})
          }, (error) => {
            console.log(error.text);
            setSent({color:"#f00",text:"Error occurred!"})
          });
        e.target.reset()
        setEmail('')
        setMessage('')
        setName('') 
    }

    return(
        <>
            <div className="contact" ref={props.contactRef}>
                <img id="projects_bye" src="/images/buildingBlocks/projectsBye.webp"/>

                <div className="columns">
                    <div className="column">
                        
                        <Fade left>
                            <div className="contact-item " id="contact">
                                <div className="text">
                                    As you have seen I am also using <img data-tip data-for="tooltip-python" alt="python" src="/images/python.webp"/> a lot.
                                    Some of the apps were fullstack projects, it was great opportunity to understand how backend works.
                                    <br/>
                                    <br/>
                                    It is important to broaden your horizons, finding new, better ways to create apps, because not tool is important, but things we do!

                                    <br/>
                                    <br/>
                                    <br/>
                                    <b>Fell free to contact me!</b>
                            </div>
                            <div className="home-text-line">
                                <a href="mailto: vlipczynski@gmail.com"><img alt="email" src="/images/email.webp"/></a>
                                <a href="https://github.com/V714" target="_blank"><img alt="github" src="/images/github.webp"/></a>
                            </div>
                                </div>
                        </Fade>
                    </div>
                    <div className="column">
                        
                        <Fade bottom>
                            <div className="contact-item item-show">
                                    <form onSubmit={(e)=>handleSubmit(e)}>
                                        <div className="grid">
                                            <div className="grid-name">
                                                <div className="title">Your Name</div>
                                                <input required name="name" label="name" type="text" value={name} onChange={(e)=>setName(e.target.value)} />
                                            </div>
                                            <div className="grid-email">
                                                <div className="title">Your e-mail</div>
                                                <input required name="email" label="email" type="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                                            </div>
                                            <div className="grid-message">
                                                <div className="title">Message</div>
                                                <textarea required name="message" label="message" value={message} onChange={(e)=>setMessage(e.target.value)}/>
                                            </div>
                                            <div className="form_bottom">
                                                <div style={{color:isSent?isSent.color:"#000"}}>{isSent?isSent.text:""}</div>
                                                <button type="submit">Send</button>
                                            </div>
                                        </div>
                                    </form>
                            </div>
                        </Fade>
                        
                    </div>
                </div>


                <div id="old">
                    <div className="column">
                        <a href="old"><img id="old_portfolio_arrows" src="/images/arrows.svg"/></a>
                    </div>
                    <div className="column">
                        <div className="row">My old portfolio</div>
                        <div className="row">(crappy design)</div>
                        <div className="row"><a href="old">check</a></div>
                    </div>
                </div>
                {props.doneAbyss?<></>:<button onClick={()=>props.setAbyss(true)} id="abyss_button">
                    ?<img id="abyss_arrows" src="/images/arrows.svg"/>
                </button>}
                <div ref={props.abyssHello} id="abyss_hello"/>
            </div>



            
            <ReactTooltip id='tooltip-python'>
                <span>Python</span>
            </ReactTooltip>
        </>
    )
}