import './styles/App.css';
import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Third from './slides/Third';
import Second from './slides/Second';
import First from './slides/First';
import Abyss from './abyss/Abyss';

function App() {

  const [projects, setProjects] = React.useState([])
  const [curretSlide, setCurretSlide] = React.useState(0)
  const [windowWidth, setWindowWidth] = React.useState()

  React.useEffect(()=>{
    setProjects([])
    addProject('Riano - Pizzeria','riano','@',['react','html','css','js'],"Frontend for restaurant in Austria, now working on the admin panel")
    addProject('Future Doctor','fd','#',['html','css','js'],"Frontend. Project is now stuck due to backend team problems.")
    addProject('Topcheese','topcheese','https://www.topcheese.link/',['react','html','css','js'],"Meme project")
    addProject('NoNutNovember Token','nnn','https://www.nonutnovembertoken.com/',['react','html','css','js'],"Advertising page for a crypto Token")
    addProject('RaspberryPi WebControl','rpisw','https://github.com/V714/RPiSensorsWebsite',['html','css','js','rpi','python','flask','sqlite'],"Web App for controlling/reading states from RaspberryPi, e.g. RGB Diode control or getting temperature from a sensor.")
    addProject('Chat','rrrchat','http://rrrchat.com/',['react','html','css','js','mongo','node'],'Entire Chat App based on socket.io, express.js and MongoDB. Now hosted on free linux server.')
    addProject('MIDI to NN','miditonn','https://main.d3varl3ejas43i.amplifyapp.com/',['react','html','css','js'],"Simple app to convert melodies into an array for easy implement in Neural Network (only PC version of website)")
    addProject('Moonzone','moonzone','https://moonzone.io/',['react'],"Place where you vote for the most ambitious token every week. Here I fixed defective html and moved it into React.")
    addProject('RockPaperScissors web3','rps','https://main.d20lejw3v5vcmi.amplifyapp.com/',['react','html','css','js'],"Rock Paper Scissors but on blockchain, for now only game with bot is avaible.")
    handleResize()
    window.addEventListener('resize', handleResize);
    document.getElementById("root").style.overflowX="unset"
  },[])

  React.useEffect(()=>{
    if(windowWidth>=1500){
      changeWaves({
        zeroBg1:'scale(1)',
        zeroBg2:'scale(1.05)',
        zeroBgR1Left:'-100vw',
        zeroBgR2Left:'-100vw',
        zeroBgR1:'rotateZ(180deg) scale(1)',
        zeroBgR2:'rotateZ(180deg) scale(1)',
        oneBg1:'scaleX(0.6) scaleY(1.6)',
        oneBg2:'scaleX(0.65) scaleY(1.7)',
        oneBgR1:'rotateZ(180deg) scaleX(1.0)',
        oneBgR2:'rotateZ(180deg) scaleX(1.0)',
        oneBgRLeft:'-20vw',
        threeBgR1Left:'-10vw',
        threeBgR2Left:'-8vw',
        threeBgR1:'rotateZ(180deg) scaleX(3.5)',
        threeBgR2:'rotateZ(180deg) scaleX(3.5)',
        threeBgD1Right:'-100vw',
        threeBgD2Right:'-100vw',
        fourBgD1Right:'0',
        fourBgD2Right:'20px', 
        fourBgR1Left:'-100vw',
        fourBgR2Left:'-100vw',
        
      })
    }
    else if(windowWidth>1250){
      changeWaves({
        zeroBg1:'scale(0.8)',
        zeroBg2:'scale(0.9)',
        oneBg1:'scaleX(0.4) scaleY(1.5)',
        oneBg2:'scaleX(0.45) scaleY(1.57)',
        zeroBgR1Left:'-100vw',
        zeroBgR2Left:'-100vw',
        zeroBgR1:'rotateZ(180deg) scale(1)',
        zeroBgR2:'rotateZ(180deg) scale(1)',
        oneBgR1:'rotateZ(180deg) scaleX(1.0)',
        oneBgR2:'rotateZ(180deg) scaleX(1.0)',
        oneBgRLeft:'-30vw',
        threeBgR1Left:'-10vw',
        threeBgR2Left:'-8vw',
        threeBgR1:'rotateZ(180deg) scaleX(3.5)',
        threeBgR2:'rotateZ(180deg) scaleX(3.5)',
        threeBgD1Right:'-100vw',
        threeBgD2Right:'-100vw',
        fourBgD1Right:'0',
        fourBgD2Right:'20px',
        fourBgR1Left:'-100vw',
        fourBgR2Left:'-100vw',
      })
    }
    else if(windowWidth>1000){
      changeWaves({
        zeroBg1:'scale(0.6)',
        zeroBg2:'scale(0.7)',
        oneBg1:'scaleX(0.3) scaleY(1.2)',
        oneBg2:'scaleX(0.35) scaleY(1.2)',
        zeroBgR1Left:'-100vw',
        zeroBgR2Left:'-100vw',

        zeroBgR1:'rotateZ(180deg) scale(1)',
        zeroBgR2:'rotateZ(180deg) scale(1)',
        oneBgR1:'rotateZ(180deg) scaleX(1.0)',
        oneBgR2:'rotateZ(180deg) scaleX(1.0)',
        oneBgRLeft:'-30vw',
        threeBgR1Left:'-10vw',
        threeBgR2Left:'-8vw',
        threeBgR1:'rotateZ(180deg) scaleX(3.5)',
        threeBgR2:'rotateZ(180deg) scaleX(3.5)',
        threeBgD1Right:'-100vw',
        threeBgD2Right:'-100vw',
        fourBgD1Right:'0',
        fourBgD2Right:'20px',
        fourBgR1Left:'-100vw',
        fourBgR2Left:'-100vw',
      })
    }
    else if(windowWidth>400){
      changeWaves({
        zeroBg1:'scale(0.3) scaleY(1.5)',
        zeroBg2:'scale(0.35) scaleY(1.3)',
        oneBg1:'scaleX(0.15) scaleY(1.2)',
        oneBg2:'scaleX(0.18) scaleY(1.2)',
        zeroBgR1Left:'-100vw',
        zeroBgR2Left:'-100vw',
        zeroBgR1:'rotateZ(180deg) scale(1)',
        zeroBgR2:'rotateZ(180deg) scale(1)',
        oneBgR1:'rotateZ(180deg) scaleX(1.0)',
        oneBgR2:'rotateZ(180deg) scaleX(1.0)',
        oneBgRLeft:'-60vw',
        threeBgR1Left:'-10vw',
        threeBgR2Left:'-8vw',
        threeBgR1:'rotateZ(180deg) scaleX(3.5)',
        threeBgR2:'rotateZ(180deg) scaleX(3.5)',
        threeBgD1Right:'-100vw',
        threeBgD2Right:'-100vw',
        fourBgD1Right:'0',
        fourBgD2Right:'20px',
        fourBgR1Left:'-100vw',
        fourBgR2Left:'-100vw',
      })
    }
    else if(windowWidth<=400){
      changeWaves({
        zeroBg1:'scale(0.2) scaleY(1.9)',
        zeroBg2:'scale(0.22) scaleY(2)',
        oneBg1:'scaleX(0.05) scaleY(1.2)',
        oneBg2:'scaleX(0.08) scaleY(1.2)',
        zeroBgR1Left:'-500vw',
        zeroBgR2Left:'-500vw',
        zeroBgR1:'rotateZ(180deg) scale(1)',
        zeroBgR2:'rotateZ(180deg) scale(1)',
        oneBgR1:'rotateZ(180deg) scaleX(1)',
        oneBgR2:'rotateZ(180deg) scaleX(1)',
        oneBgRLeft:'-170vw',
        threeBgR1Left:'-250vw',
        threeBgR2Left:'-248vw',
        threeBgR1:'rotateZ(180deg) scaleX(2.5)',
        threeBgR2:'rotateZ(180deg) scaleX(2.5)',
        threeBgD1Right:'-200vw',
        threeBgD2Right:'-200vw',
        fourBgD1Right:'-145vw',
        fourBgD2Right:'-140vw',
        fourBgR1Left:'-200vw',
        fourBgR2Left:'-200vw',
      })
    }

  },[curretSlide,windowWidth])

  const changeWaves = (data) => {
    const bg1 = document.getElementById("oldbackground").style
    const bg2 = document.getElementById("oldbackground2").style
    const bgR1 = document.getElementById("oldbackgroundR").style
    const bgR2 = document.getElementById("oldbackgroundR2").style
    const bgD1 = document.getElementById("oldbackgroundD").style
    const bgD2 = document.getElementById("oldbackgroundD2").style

    switch(curretSlide){
      case 0: 
          bg1.transition='2.7s all ease-in-out'
          bg2.transition='2.7s all ease-in-out'
          bg1.transform=data.zeroBg1
          bg2.transform=data.zeroBg2
          bgR1.transform=data.zeroBgR1
          bgR2.transform=data.zeroBgR2

          bgR1.left=data.zeroBgR1Left
          bgR2.left=data.zeroBgR2Left
        break;
      case 1:
          bg1.transform=data.oneBg1
          bg2.transform=data.oneBg2
          bg1.right='0'
          bg2.right='0'
          bg2.top='0'
          bg1.top='0'
          bg2.bottom='unset'
          bg1.bottom='unset'

          bgR1.left=data.oneBgRLeft
          bgR2.left=data.oneBgRLeft
          bgR1.bottom='0'
          bgR2.bottom='0'
          bgR1.filter='blur(0px)'
          bgR2.filter='blur(0px)'
          bgR1.transform=data.oneBgR1
          bgR2.transform=data.oneBgR2

        break;
      case 2:
        bg1.transition='0.7s all ease-in-out'
        bg2.transition='0.7s all ease-in-out'
        bg1.transform='scaleX(1) scaleY(1.0)'
        bg2.transform='scaleX(1) scaleY(1.0)'
        bg1.right='-200vh'
        bg2.right='-200vh'

        bgR1.left=data.threeBgR1Left
        bgR2.left=data.threeBgR2Left
        bgR1.bottom='-5vh'
        bgR2.bottom='-5vh'
        bgR1.filter='blur(0)'
        bgR2.filter='blur(0)'
        bgR1.transition='3.3s all ease-out'
        bgR2.transition='3.3s all ease-out'
        bgR1.transform=data.threeBgR1
        bgR2.transform=data.threeBgR2

        bgD1.right=data.threeBgD1Right
        bgD2.right=data.threeBgD2Right

        break;
      case 3:
        bgR1.transition='0.5s all ease-out'
        bgR2.transition='0.5s all ease-out'
        bgR1.bottom='50vh'
        bgR2.bottom='50vh'
        bgR1.left=data.fourBgR1Left
        bgR2.left=data.fourBgR2Left
        bgR1.transform='rotateZ(225deg)'
        bgR2.transform='rotateZ(225deg)'

        bgD1.right=data.fourBgD1Right
        bgD2.right=data.fourBgD2Right

        break;
    }
  }
  


  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const addProject = (title,pngName,url,technologies,desc) => {
    const project = {
      title: title,
      image: pngName,
      url: url,
      technologies: technologies,
      description: desc,
    }

    setProjects(prevState => [...prevState, project])
  }

  const testLoad = (that,id) => {
    setCurretSlide(id.index)
  }

  return (
    <div id='old-body'>
      <a href="/#contact" id="go_back">Go back!</a>
      <div>
        <div id='oldbackground2'>
        <svg width={800} height={700}>
          <path width={800} height={700} fill='#0002' style={{transition:'2s all ease-in-out'}} id="graypath"  d={`M0 0 H 800 V 700 Q 800 660, 700 613 T 500 437.5 T 300 263 T 100 88 T 0 0 `}/></svg>
        </div>
        <div id='oldbackground'>
          <svg width={800} height={700}>
          <path width={800} height={700} fill='#000' style={{transition:'2s all ease-in-out'}} id="blackpath" d={`M0 0 H 800 V 700 Q 800 660, 700 613 T 500 437.5 T 300 263 T 100 88 T 0 0 `}/></svg>
        </div>

        <div id='oldbackgroundR2'>
        <svg width={800} height={700}>
          <path width={800} height={700} fill='#0002' style={{transition:'2s all ease-in-out'}} id="graypath"  d={`M0 0 H 800 V 700 Q 800 660, 700 613 T 500 437.5 T 300 263 T 100 88 T 0 0 `}/></svg>
        </div>
        <div id='oldbackgroundR'>
          <svg width={800} height={700}>
          <path width={800} height={700} fill='#000' style={{transition:'2s all ease-in-out'}} id="blackpath" d={`M0 0 H 800 V 700 Q 800 660, 700 613 T 500 437.5 T 300 263 T 100 88 T 0 0 `}/></svg>
        </div>

        <div id='oldbackgroundD2'>
        <svg width={800} height={700}>
          <path width={800} height={700} fill='#0002' style={{transition:'2s all ease-in-out'}} id="graypath" d={`M0 0 H 800 V 700 Q 800 660, 700 613 T 500 437.5 T 300 263 T 100 88 T 0 0 `}/></svg>
        </div>
        <div id='oldbackgroundD'>
          <svg width={800} height={700}>
          <path width={800} height={700} fill='#000' style={{transition:'2s all ease-in-out'}} id="blackpath" d={`M0 0 H 800 V 700 Q 800 660, 700 613 T 500 437.5 T 300 263 T 100 88 T 0 0 `}/></svg>
        </div>

      </div>
      
      <div id="oldpageInner">
      <ReactFullpage
      
      anchors={["first", "second", "third","abyss"]}
      scrollingSpeed = {700} 
      onLeave={(e,a)=>testLoad(e,a)}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <First/>
            </div>
            <div className="section">
              <Third/>
            </div>
            <div className="section">
              <Second projects={projects}/>
            </div>
            <div className="section">
              <Abyss/>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
      </div>
    </div>
  );
}

export default App;
