import '../styles/Third.css';
import React from 'react';

function Third(props) {


  return (
    <>
    
    <div className='oldcontact'>
        <div className='oldcontact-title'>about me:</div>
        <div className='oldcontact-panel'>
        <div className='oldtechs-line third-line'>
            ~~~ NOT AVAILABLE - Check New Portfolio ~~~
            </div>
           
        </div>
    </div>
    </>
  );
}

export default Third;
