import ReactTooltip from "react-tooltip"

export default function Project(props){
    return(
        <>
            <div className="project-div">
                <div className="project-techs">
                    {props.project.techs && props.project.techs.map((item2,index)=><img data-tip data-for={"tooltip-"+item2}  key={index} alt={item2} src={`/images/${item2}.webp`}/>)}
                </div>
                <div className="project-title">{props.project.title}</div>
                <video className="vid" playsInline width="1920" height="1080" loop autoPlay muted>
                    <source src={`/videos/${props.project.video}.mp4`} type="video/mp4"/>
                </video>
                <button onClick={()=>props.setDetails(props.project)}>Check details!</button>
            </div>

            

            <ReactTooltip id='tooltip-react'>
                <span>React</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-python'>
                <span>Python</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-rpi'>
                <span>Raspberry Pi</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-flask'>
                <span>Flask - a backend module for Python</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-blender'>
                <span>Blender</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-node'>
                <span>Node.js</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-mongo'>
                <span>MongoDB</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-ffmpeg'>
                <span>FFmpeg</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-solidity'>
                <span>Solidity</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-nn'>
                <span>Machine Learning</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-electron'>
                <span>Electron.js</span>
            </ReactTooltip>
        </>
        )
}