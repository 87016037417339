import {
    LiveProvider,
    LiveEditor,
    LiveError,
    LivePreview
  } from 'react-live' 
import nightOwl from 'prism-react-renderer/themes/nightOwl'
import ReactTooltip from 'react-tooltip'
import Fade from 'react-reveal/Fade'
import "../styles/about.css"
import "../styles/editor.css"

const code = 
`function why(){

    const advantages = ["Modular", "Virtual DOM", 
                        "Ease of use", "Many Libraries"]

    return(
        <div className="about-item">
            <div className="body">
                <div className="body-title title-left">
                    <b>Why 
                    <img className="react-rotate" 
                        alt="react" 
                        src="/images/react.webp"
                    /> 
                    ?</b>
                </div>
                <div className="body-text">
                    <ul>
                        {advantages.map((item,index)=>{return(
                            <li key={index}>{item}</li>
                        )})}
                    </ul>
                    <div className="body-text-2">
                        It is the <b>most popular</b> 
                        framework (it's actually a 
                        library) - that means it is 
                        <b>easy</b> to find help
                        and ready solutions for 
                        encountered problems.
                    </div>
                </div>
            </div>
        </div>
    )
}`

export default function About(props){
    return(
        <>
            <div className="about" ref={props.aboutRef}>
                <img id="home_bye" src="/images/buildingBlocks/homeBye.webp"/>
                <img id="about_middle" src="/images/buildingBlocks/aboutMiddle.webp"/>

                <div id="about-inner">
                    <div className='about-column'>
                        <Fade left>
                            <div className="about-item" id="about">
                                <div className="body">
                                    <div className="body-title">{<img data-tip data-for="tooltip-react"  className="react-rotate" alt="react" src="/images/react.webp"/>}</div>
                                    <div className="body-text">
                                    I have made already tens of apps in <img data-tip data-for="tooltip-react" className="react-rotate" alt="react" src="/images/react.webp"/> learning 
                                    something new each time about optimization and new 
                                    technologies. I am using <img data-tip data-for="tooltip-sass" alt="sass" src="/images/sass.webp"/> for <b>faster</b>, <b>clean-code</b> styling
                                    and <img data-tip data-for="tooltip-redux" alt="redux" src="/images/redux.webp"/> to store data and avoid prop-drilling,
                                    in smaller projects I just stay with <img data-tip data-for="tooltip-context" id="useContext" alt="useContext()" src="/images/useContext.webp"/> to <b>minimalize</b> website.
                                    </div>
                                </div>
                            </div>
                        </Fade>

                        <LiveProvider code={code} theme={nightOwl} >
                        <Fade right>
                            <div id="editor">
                                <div className='item-title'>Try JSX by yourself and edit the panel next to the editor!</div>
                                <div className='title' id='editor_handle'>LIVE JSX EDITOR</div>
                                
                                <div id="editor_inside">
                                    <LiveEditor/>
                                </div>
                                <div id="error_inside">
                                   <LiveError />
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <LivePreview />
                        </Fade>
                    </LiveProvider>

                    </div>
                    
                    <div className='about-column'>
                        <Fade right>
                            <div className="about-item">
                                <div className="title">
                                    <div className="title-line">I write very efficient code, optimazed for SEO.</div>
                                    <div className="title-line">Performance points by <b>Lighthouse</b> Tool:</div>
                                </div>
                                <div className="body">
                                    <div className="body-text" ref={props.lighthouseView}>
                                    <video ref={props.lighthouseVid} id="lighthouseVid" width="1920" height="1080" playsInline muted>
                                        <source src="/videos/light.mp4" type="video/mp4"/>
                                    </video>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                   

                </div>


            </div>

            <ReactTooltip id='tooltip-react'>
                <span>React</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-sass'>
                <span>SASS - writing CSS can be relaxing!</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-redux'>
                <span>Redux - library for storing states in one place!</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-context'>
                <span>React hook - like Redux but lighter.</span>
            </ReactTooltip>
        </>
    )
}