import "../styles/home.css"
import Fade from 'react-reveal/Fade'
import { useEffect, useRef } from "react"


export default function Home(props){
    const mainVideo = useRef()
    useEffect(()=>{
        setTimeout(()=>{if(window.scrollY<500 && !window.location.href.includes("#"))setTimeout(()=>{if(mainVideo.current.currentTime===0)alert("This website will not load correctly if you're on Energy-Saving mode! Please turn it off while browsing this site. If you are still getting this message, it can be because of slow internet - in this case just ignore it and wait for the page to load.")},5000)},300)
    },[])
    return(
        <>
            <div id="home" ref={props.homeRef}>
                <video id="homeVidSrc" ref={mainVideo} width="1920" height="1080" playsInline loop autoPlay muted>
                    <source src="/videos/home.mp4" type="video/mp4"/>
                </video>

                <div className="home-text-div">
                    <Fade top>
                        <div className="home-text-line"><span> </span> Hi!</div>
                    </Fade>
                    <Fade left delay={200}>
                        <div className="home-text-line">I'm Tomasz,</div>
                    </Fade>
                    <Fade left delay={500}>
                        <div className="home-text-line">your next</div>
                    </Fade>
                    <Fade left delay={800}>
                        <div className="home-text-line">Front end React developer</div>
                    </Fade>
                    <Fade delay={2000}>
                        <div className="home-text-line">
                            <a href="mailto: vlipczynski@gmail.com"><img alt="email" src="/images/email.webp"/></a>
                            <a href="https://github.com/V714" target="_blank"><img alt="github" src="/images/github.webp"/></a>
                            <a href="https://www.linkedin.com/in/vlipczynski/" target="_blank"><img alt="github" src="/images/linkedin.webp"/></a>
                        </div>
                    </Fade>
                </div>
            </div>
        </>
    )
}