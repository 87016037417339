

import { useState,useEffect } from "react"
import "../styles/navbar.css"

export default function Nav(props){
    const [checkbox, setCheckbox] = useState(false)
    
    return(
    <>
        <nav id="mobile_menu" role="navigation">
            <div id="menuToggle">
                <input checked={checkbox} onChange={()=>setCheckbox(!checkbox)} type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
                
                <ul id="menu">
                    <a onClick={()=>setCheckbox(false)} href="#home"><li>Home<span className="line-divide"></span></li></a>
                    <a onClick={()=>setCheckbox(false)} href="#about"><li>About<span className="line-divide"></span></li></a>
                    <a onClick={()=>setCheckbox(false)} href="#projects"><li>Projects<span className="line-divide"></span></li></a>
                    <a onClick={()=>setCheckbox(false)} href="#contact"><li>Contact<span className="line-divide"></span></li></a>
                </ul>
            </div>
        </nav>
        <nav role="navigation" id="nav" ref={props.navbarRef}>
                <ul id="menu">
                    <a href="#home" className={props.currentSection===0 ? "a-active":""}><li><span className="line-divide"></span><div className="text">Home</div></li></a>
                    <a href="#about" className={props.currentSection===1 ? "a-active":""}><li><span className="line-divide"></span><div className="text">About</div></li></a>
                    <a href="#projects" className={props.currentSection===2 ? "a-active":""}><li><span className="line-divide"></span><div className="text">Projects</div></li></a>
                    <a href="#contact" className={props.currentSection===3 ? "a-active":""}><li><span className="line-divide"></span><div className="text">Contact</div></li></a>
                </ul>
        </nav>
    </>)
}