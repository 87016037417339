import Nav from "./items/Nav";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home"
import Projects from "./pages/Projects";
import Abyss from "./items/abyss/Abyss"
import { useInView } from "react-intersection-observer";
import { useState, useEffect, useRef } from "react";
import "./styles/abyss.css"
import "./styles/responsiveness.css"


export default function Portfolio (){
    const [currentSection, setCurrentSection] = useState(0)
    const [abyss,setAbyss] = useState(undefined)
    const [doneAbyss,setDoneAbyss]= useState(false)
    const lighthouseVid = useRef()
    const abyssHello = useRef()
    const navbarRef = useRef()

    const [lighthouseView, lighthouseInView] = useInView({
        threshold: 0.1
    });
    const [homeRef, homeInView] = useInView({
        threshold: 0.1
    });
    const [aboutRef, aboutInView] = useInView({
        threshold: 0.1
    });
    const [projectsRef, projectsInView] = useInView({
        threshold: 0.1
    });
    const [contactRef, contactInView] = useInView({
        threshold: 0.1
    });

    useEffect(()=>{
        if(!aboutInView&&projectsInView||contactInView)navbarRef.current.style.background="#8397a645"
        else navbarRef.current.style.background="#33475645"

        if(homeInView){setCurrentSection(0);return;}
        if(aboutInView){setCurrentSection(1);return;}
        if(projectsInView){setCurrentSection(2);return;}
        if(contactInView){setCurrentSection(3);return;}


    },[homeInView,aboutInView,projectsInView,contactInView])

    useEffect(()=>{
        if(lighthouseInView)setTimeout(()=>{lighthouseVid.current.play()},500)
    },[lighthouseInView])

    useEffect(()=>{
        if(doneAbyss)abyssHello.current.style.boxShadow = "0px 0px 59px 100px #fff"
    },[doneAbyss])

    useEffect(()=>{
        if(abyss){
            document.getElementById("root").style.overflowY = "hidden"
            document.getElementById("abyssBody").scrollIntoView()
            setTimeout(()=>{document.getElementById("abyssBody").style.position="fixed"},400)
        } else if(abyss===false) { 
            document.getElementById("root").style.overflowY = "unset"
            document.getElementById("contact").scrollIntoView()
        }   
    },[abyss])

    return(
        <>
            <Nav navbarRef={navbarRef} currentSection={currentSection} />
            <Home homeRef={homeRef}/>
            <About aboutRef={aboutRef} lighthouseVid={lighthouseVid} lighthouseView={lighthouseView}/>
            <Projects projectsRef={projectsRef}/>
            <Contact abyssHello={abyssHello} doneAbyss={doneAbyss} contactRef={contactRef} setAbyss={setAbyss}/>
            {abyss?<Abyss setAbyss={setAbyss} abyss={abyss} doneAbyss={doneAbyss} setDoneAbyss={setDoneAbyss}/>:<></>}
        </>
    )
}