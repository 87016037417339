import ReactTooltip from "react-tooltip"

export default function ProjectModal(props){
    return(<>
        {props.project ? 
        <div className="modal-project">
            <button className="leave_project" onClick={()=>props.setIsOpen(false)}><img alt="back arrows" src="/images/arrows.svg"/></button>
            <div className="modal-inner">
                <div className="grid">

                        <div className="grid-title">
                            <div className="project-techs">
                                {props.project.techs && props.project.techs.map((item2,index)=><img data-tip data-for={"tooltip-"+item2} key={index} alt={item2} src={`/images/${item2}.webp`}/>)}
                            </div>
                            <div className="project-title">{props.project.title}</div>
                        </div>

                        <div className="grid-desc">
                            <div className="project-desc">{props.project.desc}</div>
                        </div>

                        <div className="grid-video">
                            <video className="vid" width="1920" height="1080" playsInline loop autoPlay muted>
                                <source src={`/videos/${props.project.video}.mp4`} type="video/mp4"/>
                            </video>
                        </div>


                        <div className="grid-github">
                            {props.project.github ? <a href={props.project.github} target="_blank">View on GitHub</a> : <button>GitHub not Available</button>}
                        </div>
                        
                        <div className="grid-website">
                            {props.project.website ? <a className="button-website" href={props.project.website} target="_blank">Check Website</a> : <button className="button-website">Website not Available</button>}
                        </div>
                    
                </div>
            </div>


            

            <ReactTooltip id='tooltip-react'>
                <span>React</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-python'>
                <span>Python</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-rpi'>
                <span>Raspberry Pi</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-flask'>
                <span>Flask - a backend module for Python</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-blender'>
                <span>Blender</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-node'>
                <span>Node.js</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-mongo'>
                <span>MongoDB</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-ffmpeg'>
                <span>FFmpeg</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-solidity'>
                <span>Solidity</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-nn'>
                <span>Machine Learning</span>
            </ReactTooltip>
            <ReactTooltip id='tooltip-electron'>
                <span>Electron.js</span>
            </ReactTooltip>
        </div>
        :
        <></>
    }</>
    )
}
